import React, { useState, useEffect } from 'react';
import * as d3 from 'd3';
import './DataPage.css'; // Import a CSS file for styling

function DataPage() {
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    const VERSION = Date.now();
    d3.json(`https://carticasiweatherdata.blob.core.windows.net/public/data/file_list.json?version=${VERSION}`)
      .then(data => {
        setFileList(data);
      })
      .catch(error => {
        console.error('Error fetching file list:', error);
      });
  }, []);

  // Function to group files by year
  const groupFilesByYear = (files) => {
    const grouped = {};
    files.forEach(file => {
      const yearMatch = file.name.match(/(\d{4})/);
      const year = yearMatch ? yearMatch[0] : 'All';
      if (!grouped[year]) {
        grouped[year] = [];
      }
      grouped[year].push(file);
    });

    return grouped;
  };

  const groupedFiles = groupFilesByYear(fileList);

  // Sort the keys of the grouped object to ensure 'All' is first and years are in descending order
  const sortedYears = Object.keys(groupedFiles).sort((a, b) => {
    if (a === 'All') return -1;
    if (b === 'All') return 1;
    return b.localeCompare(a);
  });

  return (
    <div>
      <h2>Fichiers de données</h2>
      <div className="file-columns">
        {sortedYears.map(year => (
          <div key={year} className="file-column">

            <ul className="data-file-list">
              {groupedFiles[year].map((item, index) => (
                <li key={index}>
                  <a target="_blank" rel="noopener noreferrer" href={item.url}>{item.name}</a>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
}

export default DataPage;
