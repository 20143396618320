import React from 'react';
import { ForecastDay } from './viz/ForecastDay';
// import ForecastTable from './viz/ForecastTable';

export const ForecastDashBoard = ({ data }) => {

  const forecastDays = [];

  for (let i = 0; i < data.dayOfWeek.length; i++) {
    forecastDays[i] = {
      validTimeLocal: data.validTimeLocal[i],
      dayOfWeek: data.dayOfWeek[i],
      narrative: data.narrative[i],
      min: data.temperatureMin[i],
      max: data.temperatureMax[i],
      sunriseTimeLocal: data.sunriseTimeLocal[i],
      sunsetTimeLocal: data.sunsetTimeLocal[i],
      moonPhase: data.moonPhase[i],
      moonPhaseCode: data.moonPhaseCode[i],
      moonPhaseDay: data.moonPhaseDay[i],
      moonriseTimeLocal: data.moonriseTimeLocal[i],
      moonsetTimeLocal: data.moonsetTimeLocal[i]
    };
  }

  return (
    <div>
      <h2>Prévisions</h2>
      {/* <ForecastTable forecast={data} /> */}
      <ul className="container flex">
        <li className="item flex-item">
          <ForecastDay data={forecastDays[0]} />
        </li>
        <li className="item flex-item">
          <ForecastDay data={forecastDays[1]} />
        </li>
        <li className="item flex-item">
          <ForecastDay data={forecastDays[2]} />
        </li>
        <li className="item flex-item">
          <ForecastDay data={forecastDays[3]} />
        </li>
        <li className="item flex-item">
          <ForecastDay data={forecastDays[4]} />
        </li>
      </ul>
    </div>
  );
};