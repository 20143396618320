import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import "./App.css";
import * as d3 from "d3";
import { WiDaySunny } from "weather-icons-react";
import Home from "./Home";
import DataPage from "./DataPage";
// import HistoryPage from "./HistoryPage";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import {
  ReactPlugin,
  withAITracking,
} from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";

const browserHistory = createBrowserHistory({ basename: "" });
var reactPlugin = new ReactPlugin();
var appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: "2b811773-6188-403b-b710-9d1ca486441a",
    enableAutoRouteTracking: true,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
  },
});
appInsights.loadAppInsights();

function configureLocale() {
  d3.timeFormatDefaultLocale({
    dateTime: "%A %e %B %Y à %X",
    date: "%d/%m/%Y",
    time: "%H:%M:%S",
    periods: ["AM", "PM"],
    days: [
      "dimanche",
      "lundi",
      "mardi",
      "mercredi",
      "jeudi",
      "vendredi",
      "samedi",
    ],
    shortDays: ["dim.", "lun.", "mar.", "mer.", "jeu.", "ven.", "sam."],
    months: [
      "janvier",
      "février",
      "mars",
      "avril",
      "mai",
      "juin",
      "juillet",
      "août",
      "septembre",
      "octobre",
      "novembre",
      "décembre",
    ],
    shortMonths: [
      "janv.",
      "févr.",
      "mars",
      "avr.",
      "mai",
      "juin",
      "juil.",
      "août",
      "sept.",
      "oct.",
      "nov.",
      "déc.",
    ],
  });
}

function App() {
  configureLocale();
  return (
    <Router>
      <div className="App">
        <header>
          <div class="logo-title">
            <WiDaySunny className="logo" size={50} color="white" />
            <h1 class="title">Météo Carticasi</h1>
          </div>
          <nav>
            <ul class="nav-menu">
              <li>
                <Link to="/">accueil</Link>
              </li>
              {/* <li><Link to="/history">historique</Link></li> */}
              <li>
                <Link to="/data">données</Link>
              </li>
            </ul>
          </nav>
        </header>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/data" element={<DataPage />} />
          {/* <Route path="/history" element={<HistoryPage />} /> */}
        </Routes>
      </div>
    </Router>
  );
}

export default withAITracking(reactPlugin, App);
