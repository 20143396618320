import React from 'react';
import * as d3 from 'd3';
import { WiThermometer, WiStrongWind, WiHumidity, WiBarometer, WiRaindrop } from "weather-icons-react";

/*
{
        "stationID": "ICARTI7",
        "obsTimeUtc": "2023-12-07T10:58:42Z",
        "obsTimeLocal": "2023-12-07 11:58:42",
        "neighborhood": "Carticasi",
        "softwareType": null,
        "country": "FR",
        "solarRadiation": null,
        "lon": 9.290414,
        "realtimeFrequency": null,
        "epoch": 1701946722,
        "lat": 42.356291,
        "uv": null,
        "winddir": 225,
        "humidity": 54,
        "qcStatus": -1,
        "metric": {
          "temp": 8,
          "heatIndex": 8,
          "dewpt": -1,
          "windChill": 8,
          "windSpeed": 0,
          "windGust": 0,
          "pressure": 1027.09,
          "precipRate": 0.00,
          "precipTotal": 0.00,
          "elev": 237
        }
      }
*/

function degToCard(value) {
  value = parseFloat(value);
  if (value <= 11.25) return 'N';
  value -= 11.25;
  var allDirections = ['NNE', 'NE', 'ENE', 'Est', 'ESE', 'SE', 'SSE', 'Sud', 'SSO', 'SO', 'OSO', 'Ouest', 'ONO', 'NO', 'NNO', 'Nord'];
  var dIndex = parseInt(value / 22.5);
  return allDirections[dIndex] ? allDirections[dIndex] : 'Nord';
}

export const CurrentDashboard = ({ data }) => {
  const iconSize = 45;
  const iconColor = "#518bff";
  var timeFormatHours = d3.timeFormat("%A %d %B à %Hh:%M");
  return (
    <div>
      <i>MAJ le {timeFormatHours(new Date(data.obsTimeLocal))}</i>
      <ul className="container flex">
        <li className="item flex-item">
          <div><WiThermometer size={iconSize} color={iconColor} /></div>
          <div><span className="item-value">{data.metric.temp}</span><span className="item-unit">°C</span></div>
        </li>
        <li className="item flex-item">
          <div><WiStrongWind className="item-icon" size={iconSize} color={iconColor} /></div>
          <div><span className="item-value">{data.metric.windSpeed}</span><span className="item-unit"> km/h ({degToCard(data.winddir)})</span></div>
        </li>
        <li className="item flex-item">
          <div><WiHumidity className="item-icon" size={iconSize} color={iconColor} /></div>
          <div><span className="item-value">{data.humidity}</span><span className="item-unit">%</span></div>
        </li>
        <li className="item flex-item">
          <div><WiBarometer className="item-icon" size={iconSize} color={iconColor} /></div>
          <div><span className="item-value">{data.metric.pressure}</span><span className="item-unit"> hPa</span></div>
        </li>
        <li className="item flex-item">
          <div><WiRaindrop className="item-icon" size={iconSize} color={iconColor} /></div>
          <div><span className="item-value">{data.metric.precipTotal}</span><span className="item-unit">mm ({data.metric.precipRate}%)</span></div>
        </li>
      </ul>
    </div>
  );
};